@use "@angular/material" as mat;
@include mat.core();

$order-api-business-primary: mat.define-palette(mat.$blue-palette, A700);
$order-api-business-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$order-api-business-warn: mat.define-palette(mat.$red-palette);
$order-api-business-theme: mat.define-light-theme((color: (primary: $order-api-business-primary,
        accent: $order-api-business-accent,
        warn: $order-api-business-warn,
      ),
    ));

@include mat.all-component-themes($order-api-business-theme);
@import "pm-helper.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/material/theming';

// angular update and tailwind integration start //
$lab-blue: #0c83ff;

.lab-bg-blue {
  background-color: $lab-blue !important;
}

.lab-text-blue {
  color: $lab-blue;
}

.lab-border-blue {
  border-color: $lab-blue;
}

.mat-mdc-tab-header {
  background-color: white;
  border-bottom: 1px solid #c7c7c7;
}

.mat-mdc-dialog-content {
  max-height: unset;
}

.mdc-dialog__actions {
  padding: 20px !important;
}

.mdc-tab {
  letter-spacing: unset;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: black;
}

// angular update and tailwind integration end //

html,
body {
  margin: 0px;
  font-family: 400 14px/20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif !important;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.apply-btn-colr {
  background-color: #1e88e5 !important;
  color: #fff !important;
}

.action-btn-colr {
  background-color: #1e88e5 !important;
  color: #fff !important;
}

.text-colr-blue {
  color: #1e88e5 !important;
}

.add-btn {
  color: #1e88e5 !important;
  background-color: #FFFFFF !important;
}

.cancel-btn {
  color: #4F4F4F !important;
  background-color: #FFFFFF !important;
}

.save-btn {
  color: white !important;
  border-color: #0c83ff !important;
  background-color: #0c83ff !important;

}

.save-btn:disabled {
  color: #8A8A8A !important;
  border-color: #DCDCDC !important;
  background-color: #DCDCDC !important;

}

.center {
  margin: 0px;
  text-align: center;
}

.right {
  margin: 0px;
  text-align: right;
}

.fullscreen-mode {
  display: none !important;
  margin-left: 0px !important;
}

hr {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
}

.basic-container {
  padding: 0px;
  height: 100%;
  width: 100%;
}

.mat-mdc-dialog-container {
  border-radius: 5px !important;
}

.mat-grid-tile .mat-figure {
  align-items: baseline !important;
  max-height: 300px !important;
}

.mat-grid-tile .mat-figure .content {
  padding: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background: dodgerblue !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle .mat-slide-toggle-thumb {
  background: white !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.store-info .mat-mdc-list-item .mat-list-item-content {
  align-items: flex-start !important;
}

.mat-mdc-card .mat-mdc-list-item {
  font-family: none;
}

.mat-mdc-list-base .mat-mdc-list-item.mat-2-line,
.mat-list-base .mat-list-option.mat-2-line {
  height: 50px !important;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.pad-2 {
  padding: 2%;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// Default style overwritten to the same value to fix iOS bug
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-outline-start {
  width: 5.5px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.custom-mat-list-option>.mat-list-item-content {
  padding: 0 !important;
  font-size: 14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.custom-mat-list-option {
  height: 43px !important;
}

.disabled-cta {
  color: rgba(0, 0, 0, 0.38) !important;
  cursor: pointer;
}

.activeTable {
  color: #0c83ff !important;
}

.login-body {
  position: absolute;
  top: 0px;
  width: 100%;
  object-position: 0% 50%;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  height: 100%;
  background-image: url("https://storage.googleapis.com/menus/static/frontend/img/businesslogin_backgournd.png");
}

.login-form {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 30em;
  height: 18em;
  margin-top: -9em;
  /*set to a negative number 1/2 of your height*/
  margin-left: -15em;
  /*set to a negative number 1/2 of your width*/
  // background-color: rgba(255, 255, 255, 0.21);
  text-align: center;
}

.login-form .heading {
  background-color: #6d9eeb;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  margin: 5% 15%;
  color: white;
}

.login-form input {
  width: 60%;
  margin: 10px;
  height: 34px;
  border-radius: 24px;
  border: 2px solid #5a89db;
  padding: 5px 16px;
  font-size: 16px;
  color: gray;
  background-color: whitesmoke;
}

.login-form button {
  width: 122px;
  height: 35px;
  border-radius: 24px;
  border: 1px solid blue;
  background: blue;
  color: white;
  font-size: 16px;
}

$widthPrecentage: (
  100,
  75,
  50,
  25
);

@each $wp in $widthPrecentage {
  %width-#{$wp} {
    width: #{$wp}\%;
  }
}

.width-100 {
  width: 98%;
  margin-right: 2%;
}

.width-75 {
  width: 68%;
  margin-right: 2%;
}

.width-70 {
  width: 68%;
  margin-right: 2%;
}

.width-65 {
  width: 63%;
  margin-right: 2%;
}

.width-60 {
  width: 58%;
  margin-right: 2%;
}

.width-55 {
  width: 53%;
  margin-right: 2%;
}

.width-50 {
  width: 48%;
  margin-right: 2%;
}

.width-45 {
  width: 43%;
  margin-right: 2%;
}

.width-40 {
  width: 38%;
  margin-right: 2%;
}

.width-35 {
  width: 33%;
  margin-right: 2%;
}

.width-30 {
  width: 28%;
  margin-right: 2%;
}

.width-25 {
  width: 23%;
  margin-right: 2%;
}

.width-20 {
  width: 18%;
  margin-right: 2%;
}

.width-15 {
  width: 13%;
  margin-right: 2%;
}

.width-10 {
  width: 8%;
  margin-right: 2%;
}

.shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
}

.bold {
  font-weight: bold;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.audio-box .wrapper {
  background: #fff !important;
  border: solid #63636333 !important;
  height: fit-content !important;
  width: auto !important;
  padding: 10px 0px !important;
}

.audio-box .wrapper .slider {
  background: #63636333 !important;
}

.audio-box .wrapper .volume-control {
  display: none !important;
}

.menu-title {
  margin: 20px;
}

.campaign-dialog .mat-dialog-container {
  overflow: scroll;
}

.campaign-dialog {
  height: 683px;
  width: 663px;
}

.offer-v2-dialog {
  height: 683px;
  width: 740px;
}

.pointer {
  cursor: pointer;
}

.contact-edit-dialog {
  height: 400px;
  width: 500px;
}

.qrcode {
  width: 160px;
  display: inline !important;

  canvas {
    border-radius: 10px;
  }
}

.qr-container {
  float: left;
  max-width: 150px;
  font-size: 12px;
  color: grey;
  text-align: center;
  margin-right: 10px;
  border-radius: 1px solid black;

  .scan-me {
    background-color: black;
    padding: 5px;
    color: white;
    border-radius: 15px;
    text-align: center;
    width: 150px;

    .label {
      font-size: 24px;
      margin: 4px;
    }
  }
}

.qr {

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle {
    color: #666666;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group-appearance-standard,
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    border-radius: 22px !important;
  }

  .option {
    width: 40%;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-appearance-standard,
  .mat-button-toggle-label-content {
    line-height: 48px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle {
    width: 150px;
    height: 40px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-checked {
    background-color: #e8f0fe;
    color: #1967d2;
    border-radius: 22px;
    border: 1px solid #1967d2 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group-appearance-standard .mat-button-toggle {
    border: 0px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border: none;
  }
}

#new-order-container {
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.orders-page {
  .mat-drawer {
    background-color: white;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-container {
    background-color: white !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  #new-order-container .mat-tab-label {
    height: 64px;
    flex: 1 1 auto;
    border-right: 1px solid #e5e5e5;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mat-tab-label-active {
      border-bottom: 2px solid #1e88e5;
    }
  }

  .mat-drawer-inner-container {
    // overflow: hidden;
  }

  // .mat-tab-label:last-child {
  //   margin-left: auto !important;
  // }
}

.order-message-table-filters {
  display: flex;
  align-items: center;

  .mat-mdc-form-field {
    width: 187px;
    margin-right: 24px;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      margin-bottom: -1.25em;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white !important;
    border: 1px solid #e5e5e5;
  }

  .clear {
    width: 90px;
  }
}

.order-container {
  border: 1px solid #e5e5e5;

  .mat-mdc-paginator .mat-mdc-paginator-container {
    justify-content: center;
  }
}

.border-1p-grey {
  border: 1px solid #e5e5e5;
}

.bg-white {
  background-color: white;
}

.menu-container {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-container {
    background-color: white !important;
  }

  .mat-expansion-panel {
    box-shadow: none;
    border: 1px solid #e5e5e5;
  }
}

.clear {
  clear: both;
}

.light-button {
  background: rgba(71, 133, 236, 0.06);
  border: 1px solid #0c83ff !important;
  border-radius: 4px;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
  justify-content: center;
}

.ta-right {
  text-align: right;
}

.mat-sidenav.fullscreen-mode {
  display: none !important;
}

.mat-drawer-content.fullscreen-mode {
  margin-left: 0 !important;
}

#campaign-dialog {

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle {
    width: 155px;
    height: 40px;
    overflow: hidden;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-appearance-standard {
    background: none;
  }
}

.store-info {

  .mat-mdc-list-base .mat-mdc-list-item.mat-multi-line .mat-list-item-content,
  .mat-list-base .mat-list-option.mat-multi-line .mat-list-item-content {
    padding-top: 0px;
  }

  .mat-mdc-list-item {
    margin-bottom: 30px;
  }
}

.store-setting {

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-appearance-standard,
  .mat-button-toggle-label-content {
    line-height: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .mat-sidenav-content {
    min-width: 100%;
  }

  .mat-mdc-dialog-container {
    overflow: hidden !important;
  }

  .snav-closed {
    width: 0px;
  }

  .app-store-settings .mat-expansion-panel-content {
    margin: 0 15px !important;
  }

  .row .col.key {
    width: 110px !important;
  }

  .row .col.value {
    width: 140px !important;
  }

  .mat-expansion-panel-body {
    padding: 0px !important;
  }

  // .width-100{width: 100%; margin-right: 0 }
  // .width-75{ width: 100%;  margin-right: 0 }
  // .width-70{ width: 100%;  margin-right: 0 }
  // .width-65{ width: 100%;  margin-right: 0 }
  // .width-60{ width: 100%;  margin-right: 0 }
  // .width-55{ width: 100%;  margin-right: 0 }
  // .width-50{ width: 100%;  margin-right: 0 }
  // .width-45{ width: 100%;  margin-right: 0 }
  // .width-40{ width: 100%;  margin-right: 0 }
  // .width-35{ width: 100%;  margin-right: 0 }
  // .width-30{ width: 100%;  margin-right: 0 }
  // .width-25{ width: 100%;  margin-right: 0 }
  // .width-20{ width: 100%;  margin-right: 0 }
  // .width-15{ width: 100%;  margin-right: 0 }
  // .width-10{ width: 100%;  margin-right: 0 }

  // .mat-form-field{
  //   padding: 10px 0;
  // }

  .menu-title {
    padding: 10px;
    margin: 0px;
  }

  .menu-container {
    padding: 20px 10px;
    width: 96%;
    overflow: hidden;
  }

  .mat-mdc-dialog-content {
    display: flex;
    flex-direction: column;
    margin: 0 -24px;
    padding: 0 15px;
    max-height: unset !important;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .mat-mdc-dialog-container {
    display: block;
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }

  .campaign-dialog {
    height: 95vh;
    width: 99vw !important;
  }

  .order-message-table-filters {
    width: 84%;
    // display: inline-block;

    .mat-mdc-form-field {
      width: 103px;
      margin-right: 6px;
      float: left;
      padding-bottom: 2px;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: white !important;
      border: 1px solid #e5e5e5;
    }

    .search {
      width: 98%;
    }

    .start {
      width: 48%;
    }

    .end {
      width: 48%;
    }

    .clear {
      width: 98%;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.setting-list.mat-mdc-list-item>.mat-list-item-content {
  display: -webkit-box !important;
}

.mat-mdc-menu-panel.action-icons {
  text-align: center;
  background: #4d88ec;
}

.mat-mdc-menu-panel.action-icons>.mat-mdc-menu-content>mat-icon {
  margin: 5px 10px;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.printer-settings {
  .mat-expansion-panel-body {
    padding: 0 15px 16px;
  }

  .ind-printer-settings {
    .mat-expansion-panel-header {
      padding: 0 10px;
    }
  }
}

.text-primary {
  color: #1e90ff;
}

// .mat-drawer, .mat-sidenav {
//   &[cdk-scrollable], &[cdkScrollable] {
//     .mat-drawer-inner-container {
//       overflow: visible;
//     }
//   }
// }

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.customTipRow div .mat-chip-list div.mat-chip-list-wrapper {
  border: 1px solid #8080806b !important;
  border-radius: 22px !important;
  width: 98% !important;
}

.pull-left {
  float: left;
}

.pill {
  background-color: white;
  border-radius: 24px;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 95px;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 5px;
}

.blue-pill {
  border: 1px solid rgba(19, 28, 251, 0.75);
  color: #131cfb;
}

.green-pill {
  border: 1px solid rgba(47, 211, 43, 0.75);
  color: #2fd32bbf;
}

.red-pill {
  color: #ce4040;
  border: 1px solid #ce4040;
}

.grey-pill {
  border: 1px solid #636363;
  color: #636363;
}

.orange-pill {
  color: orange;
  border: 1px solid orange;
}

.blue-pill-filled {
  background-color: #4785ec;
  color: white;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 290px;
  border: 1px solid #e5e5e5;
}

.styled-table th,
.styled-table td {
  text-align: center;
  padding: 10px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

#google-visualization-errors-0 {
  display: none;
}

.menu-card {
  box-shadow: none !important;
}

@media screen and (max-width: 599px) {
  .filter-field-height {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 0 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.6em 0 0.6em 0 !important;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .filter-field-height {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 0 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.6em 0 0.6em 0 !important;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff !important;

  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .padding-none .mat-list-item-content {
    padding: 0px !important;
  }
}

.padding-none p {
  margin: 0px !important;
  color: gray;
}

// .button-height {
//   .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//       padding: 12px !important;
//   }
// }
.buton-label {

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-label-content {
    margin-top: 8px;
  }
}

.receipt-name-field {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    padding: 0px !important;
    border-top: none !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff !important;
}

// .mat-expansion-panel-content {
//   font: 400 24px/20px Roboto, "Helvetica Neue", sans-serif;
// }

// .remove-padding {
//   .mat-expansion-panel-body {
//     padding: 14px 39px 16px !important;
//   }
// }

.remove-border {
  border: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.padding-none .mat-list-item-content {
  padding: 0px !important;
}

.padding-none p {
  margin: 0px !important;
  color: gray;
}

.remove-scroll {
  .mat-drawer-inner-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.back-color-fullscreen {
  background-color: #fafafa;
}

.right-align {
  text-align: right;
}

.gift-order-table-filters {
  display: flex;
  align-items: center;

  .mat-mdc-form-field {
    width: 187px;
    margin-right: 24px;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      margin-bottom: -1.25em;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white !important;
    border: 1px solid #e5e5e5;
  }

  .clear {
    width: 90px;
  }
}

@media screen and (max-width: 768px) {
  .gift-order-table-filters {
    width: 84%;
    display: inline-block;

    .mat-mdc-form-field {
      width: 103px;
      margin-right: 6px;
      float: left;
      padding-bottom: 2px;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: white !important;
      border: 1px solid #e5e5e5;
    }

    .search {
      width: 98%;
    }

    .start {
      width: 48%;
    }

    .end {
      width: 48%;
    }

    .clear {
      width: 98%;
    }
  }
}

.points-field {
  width: 100px;

  .mat-mdc-form-field {
    width: 40%;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-flex {
    height: 64px;
    // width: 80% !important;
  }

  .mat-mdc-input-element {
    font-size: 21px;
    text-align: center;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    padding: 11px 0px 11px 0px;
  }
}

.loyalty-grph {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    height: 100%;
    overflow: hidden !important;
  }
}

.custom-tip-mobile {
  .mat-mdc-dialog-container {
    position: absolute;
    top: 20%;
    width: 50%;
    left: 26%
  }
}

@media (max-width: 599px) {
  .instruction-mobile-field {
    width: 100% !important;
  }

  .time-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      padding: 0px 10px 14px 10px !important;
    }
  }

  .order-message-table-filters {
    width: 100%;
    // display: inline-block;

    .mat-mdc-form-field {
      width: 103px;
      margin-right: 6px;
      float: left;
      padding-bottom: 2px;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: white !important;
      border: 1px solid #e5e5e5;
    }

    .search {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 7px;
    }

    .start {
      width: 49%;
      margin-right: 7px;
      margin-bottom: 7px;
    }

    .end {
      width: 49%;
      margin-right: 0px;
      margin-bottom: 7px;
    }

    .clear {
      width: 49%;
      margin-right: 7px;
      float: revert;
    }

    .btn-gap {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }

  .chipsMine {
    // height: 21px;
    color: white !important;
    background: rgba(0, 0, 0, 0.2) !important;
    border-radius: 3px !important;
    // margin: 0 0 5px 10px;
    font-size: 9px !important;
    min-height: inherit !important;
    padding: 6px 4px 0px 4px !important;
    height: 16px !important;
    margin: 1px 0px 0px 10px !important;
  }

  // .portions {
  //   .chip {
  //     padding: 1px 4px 0px 4px !important;
  //     height: 17px !important;
  //     margin: 2px 0px 5px 10px !important;
  //   }
  // }

  .chip1 {
    padding: 7px 4px 6px 4px !important;
    height: 17px !important;
    margin: 2px 0px 5px 10px !important;
  }

  // .order-smry {
  //   .mat-drawer-container {
  //     .mat-icon-button {
  //       margin-top: 15px !important;
  //       z-index: 2 !important;
  //     }
  //   }
  // }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .pos-menu-page {
    .left-right-radio-buttons {
      .mat-button-toggle {
        width: 100% !important;

      }

      .mat-button-toggle-label-content {
        padding: 0px 4px !important;
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0px 7px !important;
  }

  .mobile-pos {
    .mat-mdc-dialog-container {
      position: absolute;
      height: 97% !important;
      width: 80% !important;
      top: 2px !important;
      overflow-y: scroll !important;
    }
  }

  .mobile-add-tip {
    .mat-mdc-dialog-container {
      position: absolute;
      height: 690px !important;
      width: 80% !important;
      top: 15px !important;
      padding: 15px !important;
    }
  }

  .cash-card-checkout {
    .mat-mdc-dialog-container {
      .mat-mdc-checkbox .mdc-form-field {
        display: block !important;
        text-align: center !important;
      }

      // height: 98% !important;
      height: 100% !important;
      overflow-y: scroll !important;
      overflow-x: scroll !important;
      // top: 8px !important;
      position: absolute !important;
      // width: 95% !important;
      // left: 10px !important;
      // padding: 14px !important;
      padding: 5px !important;
    }
  }

  .trend-dialog {
    .mat-mdc-dialog-container {
      // height: 98% !important;
      height: 100vh !important;
      overflow-x: hidden !important;
      // top: 6px;
      position: absolute;
      // width: 95%;
      width: 100vh;
      // left: 10px;
    }
  }

  .subtract-mr-5 {
    .mat-drawer-inner-container {
      height: 86vh !important;
    }
  }

  .iframe-wrapper {
    .modal-container {
      .modal {
        width: 100% !important;
      }
    }
  }

  .custom-tip-mobile {
    .mat-mdc-dialog-container {
      height: 366px !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      top: 118px;
      position: absolute;
      width: 95%;
      left: 10px;
    }
  }

  .refund-mobile {
    .mat-mdc-dialog-container {
      position: absolute;
      height: 668px !important;
      width: 80% !important;
      top: 15px !important;
    }
  }

  .checkout-mobile {
    .mat-mdc-dialog-container {
      // height: 96% !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      // top: 20px;
      position: absolute;
      // width: 95%;
      // left: 10px;
      // padding: 15px !important;
    }
  }

  .total-balance-split-options {
    .mat-mdc-dialog-container {
      height: 93% !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      top: 24px;
      position: absolute;
      width: 95%;
      left: 10px;
      padding: 0px !important;
    }
  }

  .close-mobile {
    margin-top: 21px !important;
  }

  .new-dlvry-design {
    margin-bottom: 45px !important;

    .mat-expansion-panel-body {
      padding: 0px 0px !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px -1px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .first-field {
      margin-top: 2px;
      margin-bottom: 26px;
    }

    .second-field {
      margin-bottom: 26px;
    }

    .name-input {

      // margin-bottom: 27px;
      input {
        width: 100%;
        padding-left: 9px;
        border: 1px solid lightgrey !important;
        background-color: #f7f7f7;
        height: 40px;
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .time-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      padding: 0px 10px 14px 10px !important;
    }
  }

  .remove-padding {
    .mat-expansion-panel-body {
      padding: 0px 0px 0px !important;
    }
  }

  .new-dlvry-design {
    margin-bottom: 45px !important;

    .mat-expansion-panel-body {
      padding: 22px 0px !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px -1px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .first-field {
      margin-top: 2px;
      margin-bottom: 26px;
    }

    .second-field {
      margin-bottom: 26px;
    }

    .name-input {

      // margin-bottom: 27px;
      input {
        width: 85%;
        padding-left: 9px;
        border: 1px solid lightgrey !important;
        background-color: #f7f7f7;
        height: 40px;
        font-size: 13px;
      }
    }
  }

  // .chipsMine {
  //   // height: 21px;
  //   color: white !important;
  //   background: rgba(0, 0, 0, 0.2);
  //   border-radius: 3px;
  //   // margin: 0 0 5px 10px;
  //   font-size: 9px;
  //   min-height: inherit;
  //   padding: 6px 4px 0px 4px !important;
  //   height: 17px !important;
  //   margin: 0px 0px 5px 10px !important;
  // }

  // .portions {
  //   .chip {
  //     // height: 21px !important;
  //     // color: white !important;
  //     // background: rgba(0, 0, 0, 0.2) !important;
  //     // border-radius: 3px !important;
  //     // // margin: 3px 0 5px 10px !important;
  //     // font-size: 9px !important;
  //     // min-height: inherit !important;
  //     padding: 6px 4px 0px 4px !important;
  //     height: 17px !important;
  //     margin: 2px 0px 5px 10px !important;
  //   }
  // }

  .chip1 {
    padding: 6px 4px 6px 4px !important;
    height: 17px !important;
    margin: 2px 0px 5px 10px !important;
  }
}

.clases {
  animation: blinker 2.5s linear infinite;

  @keyframes blinker {
    50% {
      background-color: #0c83ff;
    }
  }
}

.tab-blink {
  .clases {
    animation: blinker 2.5s linear infinite;

    @keyframes blinker {
      50% {
        background-color: #0c83ff;
      }
    }
  }
}

.store-setting-panel-body {
  .mat-expansion-panel-body {
    padding: 0 24px 16px;
  }
}

.special-hour-picker {
  .timepicker {
    width: 320px !important;

    .timepicker__main-content {
      .timepicker__body {
        padding: 16px 5px 10px 5px;
      }
    }

    .timepicker__header {
      background-color: #4785ec !important;
    }

    .clock-face__clock-hand {
      background-color: #4785ec !important;

      &::after {
        background-color: #4785ec !important;
      }

      &::before {
        border-color: #4785ec !important;
      }
    }

    .clock-face__number {
      >span.active {
        background-color: #4785ec !important;
      }
    }

    button:not(.timepicker-dial__item) {
      color: #4785ec;
      padding: 1px 4px 12px 15px;
    }

    .timepicker__actions {
      padding: 1px 4px 10px 15px;
    }
  }
}

// Time-Picker button Color Change Start

.timepicker__header {
  background: #1e88e5 !important;
  color: #fff !important;
}

.clock-face__clock-hand {
  background: #1e88e5 !important;
  color: #fff !important;

  &::after {
    background: #1e88e5 !important;
    color: #fff !important;
  }

  &::before {
    background: #1e88e5 !important;
    color: #fff !important;
  }
}

.clock-face__number {
  >span.active {
    background: #1e88e5 !important;
    color: #fff !important;
  }
}

.timepicker-button :not(.timepicker-dial__item) {
  color: #1e88e5 !important;
}

// Time-Picker button Color Change End..

.special-hour-dialog {
  .mat-mdc-dialog-container {
    overflow: hidden;
  }

  .mat-mdc-dialog-content {
    margin: 0px;
    padding: 0px;
    overflow-y: scroll;
  }

  .new-date-btn {
    left: 3px;
    bottom: 2px;
  }
}

.Holidays-dialog {
  .mat-dialog-container {
    overflow: hidden;
  }

  .mat-dialog-content {
    margin: 0px;
    padding: 0px;
    min-height: 300px !important;
  }

  .new-date-btn {
    left: 3px;
    bottom: 2px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.open-type.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.working-hour-field .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 7px !important;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px dashed grey;
  height: 530px;
  overflow: hidden;
}

.cropper-canvas {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.working-view {
  .mat-dialog-container {
    overflow: hidden;
  }
}

.mat-expansion-panel-header-title {
  font-weight: 400 !important;
}

.field-padding {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    padding: 0px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.print-button-alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  width: 290px;
  margin: 10px auto;

  .mat-form-field .mat-form-field-wrapper {
    margin-bottom: -1.25em;
  }
}

.pos-menu-page {
  .search-filter-pos {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }
}

@media (max-width: 932px) {
  .store-setting-panel-body .mat-expansion-panel-body {
    padding: 0 0px 16px !important;
  }
}

@media screen and (max-width: 599px) {
  .mat-drawer-content {
    overscroll-behavior-y: none !important;
  }
}

.print-button-alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  width: 290px;
  margin: 10px auto;

  .mat-drawer-inner-container {
    overscroll-behavior-y: none !important;
  }

  .mod-froup-checkbox {
    min-width: 80px !important;

    .mdc-checkbox {
      padding-bottom: 0px !important;
    }

    label {
      top: -7px !important;
      left: -12px !important;
      position: absolute !important;
    }
  }

  .edit-screen-form {
    .mdc-floating-label {
      width: 100% !important;
    }
  }

  .add-screen-form {
    .mdc-floating-label {
      width: 100% !important;
    }
  }

}

@media (min-width: 960px) and (max-width: 1359px) {
  .new-dlvry-design {
    margin-bottom: 10px !important;

    .mat-expansion-panel-body {
      padding: 20px 8px !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px -1px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .first-field {
      margin-top: 2px;
      margin-bottom: 26px;
    }

    .second-field {
      margin-bottom: 26px;
    }

    .name-input {

      // margin-bottom: 27px;
      input {
        width: 100%;
        padding-left: 9px;
        border: 1px solid lightgrey !important;
        background-color: #f7f7f7;
        height: 40px;
        font-size: 13px;
      }
    }
  }

  .remove-padding {
    .mat-expansion-panel-body {
      padding: 12px 8px 12px !important;
    }
  }
}

@media (min-width: 1360px) {
  .new-dlvry-design {
    // margin-bottom: 25px !important;
    .mat-expansion-panel-body {
      padding: 22px 39px !important;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px -1px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .first-field {
      margin-top: 2px;
      margin-bottom: 26px;
    }

    .second-field {
      margin-bottom: 26px;
    }

    .name-input {

      // margin-bottom: 27px;
      input {
        width: 100%;
        padding-left: 9px;
        border: 1px solid lightgrey !important;
        background-color: #f7f7f7;
        height: 40px;
        font-size: 13px;
      }
    }
  }
}

.number-kbrd {
  .hg-theme-default .hg-button {
    height: 51px !important;
    // width: 20px !important;
  }
}

.order-view {
  background-color: #007bff;
}

.width-10 {
  width: 10%;
  margin-right: 2%;
}

.width-8 {
  width: 8%;
  margin-right: 2%;
}

.remove_icon {
  .mat-icon-button {
    line-height: 25px !important;
    width: 23px !important;
    height: 23px !important;
    line-height: 25px !important;
    width: 23px !important;
    height: 23px !important;
    right: 10px;
  }
}

.qty-select {
  margin-left: -9px !important;
  margin-top: 9px !important;
  // max-height: 400px !important;
  padding: 0px 0 !important;

  .mat-mdc-option {
    min-height: 30px !important;
  }

  .mdc-list-item {
    padding-left: 5px !important;
    padding-right: 1px !important;
  }

  .mat-mdc-option .mat-pseudo-checkbox-minimal {
    display: none !important;
  }
}

.audio-box .wrapper .slider {
  background: #1e88e5 !important;
}

.audio-box.slidecontainer.time-line {
  color: #1e88e5 !important;
  display: flex;
  justify-content: space-between;
  margin: 7px 0 2px;
  width: 87%;
}

.audio-box .wrapper .volume-control {
  display: none !important;
  color: #1e88e5 !important;
}

.time-line {
  color: #1e88e5 !important;
}

.wrapper {
  color: #1e88e5 !important;
}

svg#volume {
  fill: #1e88e5 !important;
}

svg#Layer_1 {
  /* color:  #1e88e5 !important; */
  fill: #1e88e5 !important;
}


.split-checks {
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    font-size: 25px !important;
  }

  .input.mat-input-element {
    margin-top: 9px !important;
  }
}

.splits-table {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.pmnt-type {
  .mat-mdc-text-field-wrapper {
    height: 64px;
    padding-top: 8px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    top: 19px;
  }
}

.split_show {
  width: 96%;

  .mdc-data-table__row {
    height: 100px !important;
  }

  .mdc-data-table__cell {
    vertical-align: baseline;
    border-bottom-width: 0px !important;
    border-bottom-style: none !important;
    color: dimgray;
    padding-left: 0px;
  }

  .mdc-data-table__cell:first-of-type {
    padding-left: 0px
  }
}

@media screen and (max-width: 599px) {
  .split_show {
    .mdc-data-table__row {
      height: 49px !important;
    }
  }
}


.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.mat-mdc-tab-group {
  max-width: 99.99%;
}

.pac-container {
  z-index: 10000 !important;
}

.pac-container:empty {
  display: none !important;
}

.input-border {

  textarea:focus,
  input:focus {
    outline: none;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.not-open {
  .mat-expansion-panel-header[aria-disabled=true] {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

/* No text selection */
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.driver-select-field {
  margin: 5px 0px;
  width: 123px;

  .mat-form-field-wrapper {
    padding-bottom: 0em !important;
  }
}

.cdk-global-overlay-wrapper {
  justify-content: center;
  align-items: flex-end;

  .cdk-overlay-pane {
    position: static;
    margin-bottom: 0px;
  }
}


.color-scheme-dark {
  background-color: #2c2c2c !important;

  .mat-tab-group {
    background-color: #2c2c2c !important;
    color: #ffffff !important;
  }

  .mat-drawer-container,
  .mat-drawer-content {
    background-color: #2c2c2c !important;
    color: #ffffff !important;
  }

  // .mat-icon {
  //   color: #ffffff !important;
  // }

  .mat-card {
    background-color: #333333 !important;
    color: #ffffff !important;
  }

  .mat-list-item {
    background-color: #444444 !important;
    color: #ffffff !important;
  }

  .mat-button {
    background-color: #555555 !important;
    color: #ffffff !important;
  }

  .back-color-fullscreen {
    background-color: #2c2c2c !important;
    color: #ffffff !important;
  }

  .mat-mdc-tab-header {
    background-color: #2c2c2c !important;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    color: #ffffff !important;

  }

  .mat-stepper-horizontal {
    background-color: #2c2c2c !important;

  }

  .show-quick-entry {
    background-color: #2c2c2c !important;
  }

  .dark-bg-color {
    background-color: #2c2c2c !important;
  }

  .hot-button {
    color: #2c2c2c;
  }

  .text-clr {
    color: white !important;
  }

  .hg-button {
    color: #2c2c2c !important;
    /* Dark background for the keys */
  }

  .hg-theme-default {
    background-color: #2c2c2c !important;
  }

  .selected {
    background-color: #1a73e8 !important;
    /* Override the selected item background color in dark mode */
    color: #ffffff !important;
    /* Ensure the text color is also overridden for better contrast */
  }

  .mat-mdc-table tbody,
  .mat-mdc-table tfoot,
  .mat-mdc-table thead,
  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-header-row,
  .mat-mdc-row,
  .mat-mdc-footer-row,
  .mat-mdc-table .mat-mdc-header-cell {
    color: white !important;
    background-color: #2c2c2c !important;

  }

  .mat-paginator {
    background-color: #2c2c2c !important;
    /* Dark background color */
    color: #ffffff !important;
    /* White text color */
  }

  .mat-mdc-paginator {
    background-color: #2c2c2c !important;
    /* Dark background color */
    color: #ffffff !important;
  }

  .price-breakdown-table tr td.item {
    color: white !important;
  }

  .mat-mdc-unelevated-button[disabled][disabled] {
    border: 1px solid white !important;
    color: white !important;
  }

  /* Add other component-specific dark mode styles here */
}

.floor-table {
  .mat-mdc-table .mdc-data-table__row {
    height: auto;
    transition: height 0.3s ease-in-out;
  }
}

.konvajs-content {
  border: 2px solid gray;
}

.floor-canvas {
  display: inline-block;
  margin: 0px 10px 10px 10px;
  border: 1px solid #ccc;
}

@media (min-width: 420px) {
  .tab-pagination-issue {
    .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
      display: none !important;
    }
  }
}

.open-close-filters {

  // height: 100%;
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
}

.above-mob {
  .mat-mdc-form-field {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 767px) {
  .table-header-cell {
    .mdc-data-table__header-cell {
      padding: 0px 8px 0px 8px !important;
    }

    .mdc-data-table__cell {
      padding: 0px 8px 0px 8px !important;

    }
  }

  .btn-open {
    .mdc-button--unelevated {
      padding: 0px 5px 0px 5px !important;

    }

    .mdc-button {
      min-width: 0px !important;

    }
  }

}

.hosted_form iframe {
  width: 100% !important;
}